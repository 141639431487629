<template>
  <v-container class="container-max-width">
    <v-row v-if="isLoading" id="syncio-loading-logo">
      <v-col class="text-center">
        <loading-syncio-logo></loading-syncio-logo>
      </v-col>
    </v-row>
    <v-row class="page-wrapper" v-else>
      <v-col>
         <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center relative">
              <!-- <v-icon large @click="$router.go(-1)" class="absolute go-back-link">
                chevron_left
              </v-icon>  -->
              <svg width="120px" height="30px" viewBox="0 0 100 30" style="transform: translateX(8px);">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="filled"/>
                <line x1="61" y1="10" x2="49" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p class="text-h8 text-center mb-2 font-weight-bold font-primary">STEP 2</p>
            <div class="text-h1 primary--text">
              Connect to your first destination store!
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <v-card class="px-8 py-12 card-rounded not-a-link">
              <v-row>
                <v-col class="text-center key-color">
                  <div class="text--label--1 text-uppercase">
                    Your store’s unique key:
                  </div>
                  <div class="text-subtitle-1">
                    {{ this.currentShop.identifier }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <div class="text-body-2">
                    Share your unique key with destination stores to allow them to import your products to sell on their store. This unique key can also be found on your dashboard after completing this setup process.
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col class="d-flex flex-row justify-space-between">
                  <v-btn class="primary" width="47.5%" height="48px" @click="$router.push('/shopify/connect-and-invite-store/invite-destination-store')">
                    <div class="text-body-1 font-weight-bold">
                      Share your key via e-mail
                      <v-icon size="20" right>
                        arrow_forward
                      </v-icon>
                    </div>
                  </v-btn>
                  <v-btn outlined class="primary-outline received-button-border" width="47.5%" height="48px" @click="$router.push('/shopify/connect-and-invite-store/connect-destination-store')">
                    <div class="text-body-1 font-weight-bold">
                      I received a destination store key
                      <v-icon size="20" right>
                        arrow_forward
                      </v-icon>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-row class="mt-7">
              <v-col class="text-center">
                <div class="text-body-2">
                  <router-link class="text-decoration-none font-weight-bold" to="/shopify/source-store-installation-complete">Skip for now.</router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";

export default {
  name: "ConnectAndInviteStore",
  components: {LoadingSyncioLogo},
  created() {
    this.$store.dispatch(`shop/loadCurrentShop`)
  },
  computed: {
    ...mapGetters("shop", ["currentShop", "isLoading"]),
  }
}
</script>

<style scoped>
.container-max-width{
  max-width: 686px !important;
}
.key-color{
  color: #FA757B;
}
.received-button-border {
  border: 2px solid #0E3B4D;
  box-sizing: border-box;
  border-radius: 10px;
}
.read-guide{
  margin-top: 6%;
}
#syncio-loading-logo {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
